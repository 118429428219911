import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    leftMenuOpen: false,
    rightMenuOpen: false,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleLeftMenu(state, action) {
            if (action?.payload) {
                state.leftMenuOpen = action.payload
            } else {
                state.leftMenuOpen = !state.leftMenuOpen
            }
        },
        toggleRightMenu(state, action) {
            if (action?.payload) {
                state.rightMenuOpen = action.payload
            } else {
                state.rightMenuOpen = !state.rightMenuOpen
            }
        }
    }
})

export const {toggleLeftMenu, toggleRightMenu} = layoutSlice.actions

export default layoutSlice.reducer