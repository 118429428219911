import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import getConfig from 'next/config'
import TagManager from 'react-gtm-module'
import NextNProgress from "nextjs-progressbar";

import {store} from '../store/store'

import '../styles/globals.css'

const {publicRuntimeConfig} = getConfig()

const App = ({Component, pageProps}) => {

    useEffect(() => {
        if (publicRuntimeConfig.environment === 'production') {
            TagManager.initialize({gtmId: 'GTM-TFL67T9'})
        }
        console.log(publicRuntimeConfig.version)
    }, []);

    return (
        <Provider store={store}>
            <NextNProgress
                color="#fff"
                startPosition={0}
                stopDelayMs={0}
                height={2}
                showOnShallow={true}
                options={{showSpinner: false}}
            />
            <Component {...pageProps} />
        </Provider>
    )
}

export default App